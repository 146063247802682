<template>
  <CommonStackedAvatars
    v-if="members"
    class="UserGroupMembers"
    :items="members"
    item-text="userLogin"
  >
    <template #item="{ item: user, on, attrs }">
      <UserAvatar
        class="UserGroupMembers__member UserGroupMembers__member"
        :user="user"
        size="sm"
        v-bind="attrs"
        v-on="on"
      />
    </template>
  </CommonStackedAvatars>
</template>

<script>
import { CommonStackedAvatars } from '@hexway/shared-front'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  name: 'UserGroupMembers',

  components: {
    UserAvatar,
    CommonStackedAvatars,
  },

  props: {
    userGroup: { type: Object, required: true },
  },

  computed: {
    members() {
      return this.$store.getters['user/groupMembers'](this.userGroup.id)
    },
  },
}
</script>

<style lang="sass">
// .UserGroupMembers
//   color: darkblue
</style>

<template>
  <div class="UserGroups">
    <AppBarLayout action-buttons-class="ml-11">
      <template #header>
        {{ $t('userGroup.UserGroups') }}
      </template>

      <template #actions>
        <v-btn
          depressed
          color="primary"
          class="mr-3"
          :to="{ query: { ...$route.query, action: 'create-group' } }"
          replace
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-plus'"
          />
          {{ $t('userGroup.Group') }}
        </v-btn>

        <v-btn
          depressed
          color="primary"
          :to="{ query: { ...$route.query, action: 'create-user' } }"
          replace
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-plus'"
          />
          {{ $t('user.User') }}
        </v-btn>
      </template>
    </AppBarLayout>

    <CommonDataTable
      class="UserGroups__table"
      :items="filteredUserGroups"
      :headers="headers"
      plain-styles
      show-actions-row
      sort-by="name"
      size="lg"
      :scrollable-height="`calc(100vh - ${$vuetify.breakpoint.mdAndUp ? 64 : 56}px - 9px)`"
      fixed-header
      :no-data-text="$t('userGroup.NoGroupsMatchingM')"
      :row-to="userGroup => ({ query: { ...$route.query, action: 'edit-group', groupId: userGroup.id } })"
      row-replace
    >
      <template #actions>
        <CommonDataTableCounter
          class="ml-8 mr-4"
          items-message="userGroup.groupsP"
          :custom-count="userGroups.length"
        />

        <CommonTextField
          v-model="searchModel"
          hide-details
          margins-with-hidden-details="mb-0"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          :placeholder="$t('userGroup.GroupName')"
        />
      </template>

      <template #header.name="{ header }">
        <span
          class="ml-4"
          v-text="header.text"
        />
      </template>
      <template #item.name="{ item: userGroup }">
        <div
          class="ml-4 text-truncate"
          style="max-width: 314px; min-width: 0"
        >
          <div
            class="text-truncate"
            v-text="userGroup.name"
          />
          <div
            class="text-truncate textSecondary--text"
            v-text="userGroup.description"
          />
        </div>
      </template>

      <template #item._members="{ item: userGroup }">
        <UserGroupMembers :user-group="userGroup" />
      </template>
    </CommonDataTable>

    <UserDialog
      :value="showUserDialog"
      :user-id="dialogUserId"
      hide-create-bot
      @input="!$event && $router.replace({ ...$route.query, action: undefined, userId: undefined })"
    />
  </div>
</template>

<script>
import moment from 'moment'
import * as R from 'ramda'

import { fmtDate, replaceRoute } from '@/helpers'

import Dialog from '@/store/orm/dialog'
import UserGroup from '@/store/orm/userGroup'

import AppBarLayout from '@/layouts/AppBarLayout'
import UserDialog from '@/components/UserDialog.vue'
import UserGroupMembers from '@/components/UserGroupMembers'

export default {
  name: 'UserGroups',

  components: {
    AppBarLayout,
    UserDialog,
    UserGroupMembers,
  },

  props: {
    showGroupDialog: { type: Boolean, default: false },
    dialogGroupId: { type: String, default: null },
    showUserDialog: { type: Boolean, default: false },
    dialogUserId: { type: String, default: null },
    searchQuery: { type: String, default: '' },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('userGroup.Name'),
          value: 'name',
        },
        {
          text: this.$t('userGroup.Participants'),
          value: '_members',
          sortable: false,
        },
        {
          text: this.$t('layout.Created'),
          value: 'created',
          displayValue: group => fmtDate(group.created),
          tooltip: group => `${this.$t('layout.Created')}: ${moment(group.created).format('LLLL')}`,
        },
      ],

      dialogInstances: {
        group: null,
        user: null,
        bot: null,
      },
    }
  },

  computed: {
    searchModel: {
      get() { return this.searchTokens },
      set(q) {
        const query = q
          ? { ...this.$route.query, q: q || '' }
          : R.omit(['q'], this.$route.query)
        replaceRoute(this.$router, { query })
      },
    },

    userGroups() {
      return UserGroup.all()
    },

    searchTokens() {
      return (this.searchQuery || '')
        .trim()
        .split(/\s+?/g)
        .map(s => s.trim().toLocaleLowerCase())
        .filter(Boolean)
    },

    filteredUserGroups() {
      const { userGroups, searchTokens } = this
      if (!userGroups) return null
      return searchTokens.length
        ? userGroups.filter(userGroup =>
          searchTokens.every(q =>
            userGroup._searchBy.includes(q)))
        : userGroups
    },

    closeModalRoute() {
      return {
        query: R.omit(['action', 'groupId', 'userId']),
      }
    },
  },

  created() {
    this.$store.dispatch('user/getList')
    UserGroup.dispatch('$getList')

    this.$watch(function() {
      const { showGroupDialog, dialogGroupId } = this
      return { showGroupDialog, dialogGroupId }
    }, this.toggleGroupDialog, { deep: true, immediate: true })
  },

  methods: {
    closeAllDialogs() {
      Promise.all(Object.values(this.dialogInstances)
        .filter(Boolean)
        .map(dialog => dialog.close()))
    },

    async toggleGroupDialog() {
      if (this.showGroupDialog) {
        await this.closeAllDialogs()
        this.dialogInstances.group = await Dialog.open({
          componentName: 'UserGroupDialog',
          props: { userGroupId: this.dialogGroupId },
          onClose: () => replaceRoute(this.$router, this.closeModalRoute),
        })
      } else if (this.dialogInstances.group) {
        this.dialogInstances.group.close()
        this.dialogInstances.group = null
      }
    },
  },
}
</script>

<style lang="sass">
.UserGroups
  &__table .VirtualScroller__scrollable
    padding-bottom: 68px
</style>
